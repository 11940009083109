import callApi, { BaseResponseDto } from "./baseRequest";

const route = 'customer';

export interface CustomerData {
    partnerResponseDtos: PartnerResponse[],
    salesDataResponseDto: SalesResponse[],
    paymentTermResponseDto: PaymentTermResponse[],
    defaultDataResponseDto: DefaultDataResponse[],
    veichleTypeResponseDto: VeichleTypesResponse[],
    multipleWeights?: MaterialMultipleWeight[],
    multipleWeight: any
}

interface MaterialMultipleWeight
{
    materialCode: string,
    multipleWeight: number
}

export interface PartnerResponse {
    SalesOrganization: string,
    DistributionChannel: string,
    ActivitySector: string,
    PartnerRole: string,
    Partner: string,
    Name: string,
    Telephone: string,
    Email: string,
    CNPJ: string,
    City: string
}

interface SalesResponse {
    SalesOrganization: string,
    DistributionChannel: string,
    ActivitySector: string,
    CustomersGroup: string,
    CustomersGroup3: string,
    PriceGroup: string,
    Incoterms1: string,
    Incoterms2: string,
    PaymentCondition: string,
    ToBeDeleted: string,
}

interface PaymentTermResponse {
    Language: string,
    PaymentCondition: string,
    Description: string,
}

interface DefaultDataResponse {
    SalesOrganization: string,
    DistributionChannel: string,
    Material: string,
    ItemCategory: string,
    Incoterm: string,
    PaymentCondition: string,
    FreightMaterialAgent: string,
    FreightAgent: string,
    FreightAgentName: string,
}

export interface VeichleTypesResponse {
    id: string,
    maxValue: number,
    minValue: number,
    tm: string,
    incoterm: string,
    veichleDescriptions: VeichleDescResponse[],
    tms: VeichleTMSResponse[],
    materialCode?: string
}

export interface VeichleDescResponse {
    id: string,
    description: string,
    lang: string,
}

export interface VeichleTMSResponse {
    id: string,
    tm: string,
    veichleId: string,
}

export interface TonsPerMonthResponse {
    materialCode: string,
    customerCode: string,
    year: number,
    january: number,
    february: number,
    march: number,
    april: number,
    may: number,
    june: number,
    july: number,
    august: number,
    september: number,
    october: number,
    november: number,
    december: number,
    id: string
};

export interface TransporterResponseModel {
    cod: string,
    name: string,
    incoterm: string
}
export interface TransporterVehicleResponseModel {
    orderId: string,
    veichles: VeichleTypesResponse[],
    transporters: TransporterResponseModel[],
    materialMultiple: MaterialMultipleWeight[]
}

export function getCustomerDataByIssuer(issuerCode: string, materialCode: string, isPVC: boolean = false): Promise<BaseResponseDto<CustomerData>> {
    const result: BaseResponseDto<CustomerData> = {
        data: {
            defaultDataResponseDto: [],
            partnerResponseDtos: [],
            paymentTermResponseDto: [],
            salesDataResponseDto: [],
            veichleTypeResponseDto: [],
            multipleWeight: 1,
            multipleWeights: undefined
        },
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',
        success: false
    };

    const response = callApi('GET', `${route}/customer-details/${issuerCode}?materialCodes=${materialCode}&isPVC=${isPVC}`)
        .then(resp => {
            if (resp.data) {
                result.data = {
                    partnerResponseDtos: resp?.data?.Partners,
                    defaultDataResponseDto: resp?.data?.DefaultData,
                    paymentTermResponseDto: resp?.data?.PaymentTerm,
                    salesDataResponseDto: resp?.data?.SalesData,
                    veichleTypeResponseDto: resp?.data.VeichleTypes,
                    multipleWeight: resp?.data.MultipleWeight,
                    multipleWeights: resp?.data.MultipleWeights,
                };
                result.success = true;
            }

            return result;
        })
        .catch(err => {
            result.error = err?.error?.error;
            result.errorPT = err?.error?.errorPT;
            result.errorEN = err?.error?.errorEN;
            result.errorES = err?.error?.errorES;
            return result;
        })

    return response;
};

export function getPartnersByIssuer(issuerCode: string): Promise<BaseResponseDto<PartnerResponse[]>> {
    const result: BaseResponseDto<PartnerResponse[]> = {
        data: [],
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',
        success: false
    };

    const response = callApi('GET', `${route}/customer-partners/${issuerCode}`)
        .then(resp => {
            if (resp.data) {
                result.data = resp?.data;                
                result.success = true;
            }

            return result;
        })
        .catch(err => {
            result.error = err?.error?.error;
            result.errorPT = err?.error?.errorPT;
            result.errorEN = err?.error?.errorEN;
            result.errorES = err?.error?.errorES;
            return result;
        })

    return response;
};


export function getTonsPerMonthByIssuer(issuerCode: string, materialCode: string, year: number): Promise<BaseResponseDto<TonsPerMonthResponse>> {
    const result: BaseResponseDto<TonsPerMonthResponse> = {
        data: {
            materialCode: '',
            customerCode: '',
            year: 0,
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0,
            id: ''
        },
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',
        success: false
    };

    const response = callApi('GET', `${route}/tons-per-month/${issuerCode}/${materialCode}/${year}`)
        .then(resp => {
            if (resp?.data ?? false) {
                result.data = resp?.data;
                result.success = true;
            }
            else {
                result.error = resp?.error?.error;
                result.errorPT = resp?.error?.errorPT;
                result.errorEN = resp?.error?.errorEN;
                result.errorES = resp?.error?.errorES;
            }

            return result;
        })
        .catch(err => {
            result.error = err?.error?.error;
            result.errorPT = err?.error?.errorPT;
            result.errorEN = err?.error?.errorEN;
            result.errorES = err?.error?.errorES;

            return result;
        })

    return response;
};

export function getBulkCustomerDataByIssuer(language: string, issuerCode: string, ordersId: string []): Promise<BaseResponseDto<TransporterVehicleResponseModel[]>> {
    const result: BaseResponseDto<TransporterVehicleResponseModel[]> = {
        data: [],
        error: '',
        errorEN: '',
        errorES: '',
        errorPT: '',
        success: false
    };

    const response = callApi('POST', `${route}/bulk-customer-details/${language}/${issuerCode}`, ordersId)
        .then(resp => {
            if (resp.data) {
                result.data = resp?.data;
                result.success = true;
            }

            return result;
        })
        .catch(err => {
            result.error = err?.error?.error;
            result.errorPT = err?.error?.errorPT;
            result.errorEN = err?.error?.errorEN;
            result.errorES = err?.error?.errorES;
            return result;
        })

    return response;
};