import '../styles/NewOrder.scss';
import newOrder from '../assets/icons/new-order-uni.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tuckLocation from '../assets/icons/ic-truck.svg';
import weightTon from '../assets/icons/new-ton.png';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import { faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays, faCalendarXmark, faCheckCircle, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import truckLocation from '../assets/icons/ic-truck2.svg';
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { Accordion, AccordionButton, Button, Spinner } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { v4 as uuidv4 } from 'uuid';
import UniModal from '../components/UniModal/UniModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Material } from '../services/materialService';
import { useUserStore } from '../store/user';
import { CustomerData, getCustomerDataByIssuer, getPartnersByIssuer, PartnerResponse } from '../services/customerService';
import { useTranslation } from 'react-i18next';
import { Delivery, DeliveryItem, DeliveryUpdate, PaymentCond, Product, Receiver, Transporter, Veichle } from '../types/newOrder';
import { getOrderToupdate, RequestOrderUpdateIntent, putOrderIntent, DeliveryResponseUpdateModel } from '../services/orderService';
import moment from 'moment';
import { useWindowSize } from '@uidotdev/usehooks';
import { StatusIntent } from '../types/orderIntent';

const EditOrder = () => {
    const [products, setProducts] = useState<Material[]>();
    const [customerData, setCustomerData] = useState<CustomerData>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedProducts, setSelectedProducts] = useState<Product[]>();
    const [selectedReceiver, setSelectedReceiver] = useState<Receiver>();
    const [selectedIncoterm, setSelectedIncoterm] = useState<string>();
    const [error, setError] = useState<string>();
    const [msgErrorDate, setErrorDate] = useState<string>('');
    const [msgErrorQuantity, setMsgErrorQuantity] = useState<string>('');
    const [msgErrorIntent, setErrorIntent] = useState<any[] | undefined>(undefined);
    const [msgErrorFileModel, setErrorFileModel] = useState<string>('');
    const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    const [showModalSucces, setShowModalSucces] = useState<boolean>(false);
    const [selectedPaymentCondition, setSelectedPaymentCondition] = useState<PaymentCond>();
    const [paymentConditions, setPaymentConditions] = useState<PaymentCond[]>();
    const [transporters, setTransporters] = useState<Transporter[]>();
    const [receivers, setReceivers] = useState<Receiver[]>();
    const [incoterms, setIncoterms] = useState<string[]>();
    const [deliveries, setDeliveries] = useState<DeliveryUpdate[]>([]);
    const [veichles, setVeichles] = useState<Veichle[]>([]);
    const [multipleWeight, setMultipleWeight] = useState<any>({matiralCode:'', multipleWeight: 1});
    const [informedQuantity, setInformedQuantity] = useState<string>('');
    const [showNotMultiple, setShowNotMultiple] = useState<boolean>(false);
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
    const [obs, setObs] = useState<string>('');
    const navigate = useNavigate();
    const { selectedCustomer, loginData} = useUserStore();
    const { i18n, t } = useTranslation();
    const size = useWindowSize();
    const [searchParams, setSearchParams] = useSearchParams();
    let { id } = useParams();
    const [data, setData] = useState<DeliveryResponseUpdateModel>();
    const [block, setBlock] = useState<boolean>(false);
    const [materials, setMaterials] = useState<any>([]);
    const [materialErrorUpdate, setMaterialErrorUpdate] = useState<any[] | undefined>(undefined);
    const [partners, setPartners] = useState<PartnerResponse[]>();
    const [modalChangedOrder, setModalChangedOrder] = useState<boolean>(false);
    const [deliveriesOld, setDeliveriesOld] = useState<DeliveryUpdate[]>([]);
    const [selectedReceiverOld, setSelectedReceiverOld] = useState<Receiver>();

    useEffect(() => {
        getPartnersByIssuer(selectedCustomer.issuer)
            .then(r => {
                if (r.success) {
                    
                    let receiverList: PartnerResponse[] = [];

                    r.data?.forEach(d => {
                        if (d.PartnerRole === 'RM' && !receiverList?.some(i => i.Partner === d.Partner)) {
                            let partner: PartnerResponse = {
                                Partner: d.Partner,
                                City: d.City,
                                CNPJ: d.CNPJ,
                                Name: d.Name,
                                ActivitySector: d.ActivitySector,
                                DistributionChannel: d.DistributionChannel,
                                SalesOrganization: d.SalesOrganization,
                                Email: d.Email,
                                PartnerRole: d.PartnerRole,
                                Telephone: d.Telephone                                
                            }
            
                            receiverList.push(partner);
                        }
                    });                 
                    setPartners(receiverList);   
                }
                else 
                {
                    setError(r.error);
                }
            })
            .catch(err => console.log(err));
    }, []);       

    useEffect(() => {
        let idOrder = '';

        if (searchParams.get('idOrder')) {
            idOrder = searchParams.get('idOrder') ?? '';
        }

        setLoadingProduct(true);
        getOrderToupdate(id ?? '', selectedCustomer.issuer, idOrder)
        .then(r => {
            if (r.success) {
                setData(r.data);
                if (r.data.orders) {
                    let orders = r.data.orders;
                    setBlock(false);

                    if (!orders.some(o => o.error == '' || o.error == null)) {
                        setBlock(true);
                    }

                    let marialsTemp: Product[] | any = [];
                    orders?.forEach(o => {
                        o.itens?.forEach(i => {
                            const isPvc = (o?.itens?.some(d=>d.products?.some(d=>d.pvc === true)));
                            i.products?.forEach(p => {
                                if (!marialsTemp?.some((m: any) => m.materialCode == p.materialCode) ?? true) {
                                    marialsTemp?.push({
                                        description: p.materialDescription,
                                        materialCode: p.materialCode,
                                        pvc: isPvc
                                    });
                                    setMaterials(marialsTemp);
                                }
                            })
                        })
                    });

                    getCustomerDataByIssuer(selectedCustomer.issuer, marialsTemp?.map((m: any) => m.materialCode)?.join(',') ?? '', true)
                    .then(c => {
                        setCustomerData(c.data);
                        setMultipleWeight(c.data.multipleWeights);
                        populateInputs(c.data, r.data.orders, r.data);
                    })
                    .catch(e => console.log(e))
                    .finally(() => setLoadingProduct(false));
                }
            }
            else {
                setErrorIntent([{error: t('newOrderScreen.labelOrderNotFound')}])
            }
        })
        .catch(err => setErrorIntent([{error: t('newOrderScreen.labelOrderNotFound')}]));
    }, []);

    const populateInputs = (customerDataR: CustomerData, deliveriesR: DeliveryUpdate[], dataR: DeliveryResponseUpdateModel) => {
        let transporterList: Transporter[] = [];
        let receiverList: Receiver[] = [];
        let incotermsList: string[] = [];
        let paymentConditionsAdd: PaymentCond[] = [];
        let veichlesAdd: Veichle[] = [];

        setSelectedProducts(materials);

        if(selectedCustomer?.country?.toUpperCase() === 'AR')
        {
            incotermsList.push('CPT');
            incotermsList.push('FCA');
        }

        if (customerDataR?.defaultDataResponseDto?.length !== 0) {
            customerDataR?.defaultDataResponseDto?.forEach(d => {
                let newTransporter: Transporter = {
                    cod: d.FreightAgent,
                    name: d.FreightAgentName,
                    incoterm: d.Incoterm?.toUpperCase()
                }

                if (!transporterList?.some(t => t.cod === newTransporter.cod)) {
                    transporterList.push(newTransporter);
                }

                let newIncoterm: string = d.Incoterm;

                if (selectedCustomer?.country?.toUpperCase() !== 'AR' && !incotermsList?.some(i => i === newIncoterm)) {
                    incotermsList.push(newIncoterm);
                }

                var description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition ?? '';

                if(description === '' || description === undefined || description === null)
                    description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: description
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }

                customerDataR?.veichleTypeResponseDto?.forEach(v => {
                    if (d.FreightMaterialAgent) {
                        if (v.tms?.some(t => t.tm === d.FreightMaterialAgent?.trim()) ?? true) {
                            if (!veichlesAdd?.some(ve => ve.tm === v.tm && ve.incoterm === v.incoterm) || veichlesAdd.length === 0) {

                                let vehiclesDescriptions = v.veichleDescriptions?.filter(d => d.lang === i18n.language.toUpperCase());

                                if(vehiclesDescriptions.length === 0)
                                    vehiclesDescriptions = v.veichleDescriptions?.filter(d => d.lang === 'PT')

                                v.tms.forEach(h=>{

                                    let newVeichle: Veichle = {
                                        id: v.id,
                                        descriptions: vehiclesDescriptions,
                                        incoterm: v.incoterm,
                                        tm: h.tm,
                                        maxValue: v.maxValue,
                                        minValue: v.minValue,
                                    };

                                veichlesAdd.push(newVeichle);

                                });
                            }
                        }
                    }
                });
            });

            if (incotermsList?.length === 0 && selectedCustomer?.country?.toUpperCase() !== 'AR') {
                incotermsList.push(dataR?.incoterms);
            }

            setSelectedIncoterm(dataR?.incoterms);
        }
        else {
            customerDataR?.partnerResponseDtos?.forEach(d => {

                if (d.PartnerRole === 'AF') {
                    let newTransporter: Transporter = {
                        cod: d.Partner,
                        name: d.Name,
                        incoterm: 'AF'
                    }

                    transporterList.push(newTransporter);
                }
            });

            customerDataR?.salesDataResponseDto?.forEach(s => {
                let newIncoterm: string = s.Incoterms1;
                if (selectedCustomer?.country?.toUpperCase() !== 'AR' && !incotermsList?.some(i => i === newIncoterm)) {
                    incotermsList.push(newIncoterm);
                }

                var description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === s.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? s.PaymentCondition ?? '';

                if(description === '' || description === undefined || description === null)
                    description = customerDataR?.paymentTermResponseDto?.filter(p => p.PaymentCondition === s.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? s.PaymentCondition ?? '';

                let paymentNew: PaymentCond = {
                    cod: s.PaymentCondition,
                    description: description
                };
                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            });

            customerDataR?.veichleTypeResponseDto?.forEach(v => {
                let newVeichle: Veichle = {
                    id: v.id,
                    descriptions: v.veichleDescriptions,
                    incoterm: v.incoterm,
                    tm: v.tm,
                    maxValue: v.maxValue,
                    minValue: v.minValue,
                };

                veichlesAdd.push(newVeichle);
            });
        }

        if (selectedCustomer?.country?.toUpperCase() !== 'AR' && incotermsList?.length === 0) {
            incotermsList.push(dataR?.incoterms);
        }

        setSelectedIncoterm(dataR?.incoterms);
        setSelectedPaymentCondition(paymentConditionsAdd?.filter(p => p.cod == dataR?.paymentCondition)?.[0]);

        customerDataR?.partnerResponseDtos?.forEach(d => {
            if (d.PartnerRole === 'RM' && !receiverList?.some(i => i.cod === d.Partner)) {
                let newReceiver: Receiver = {
                    cod: d.Partner,
                    address: `${d.CNPJ ?? ''} - ${d.Name} - ${d.City ?? ''}`
                }

                receiverList.push(newReceiver);
            }
        });

        if (customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0] ?? false) {
            setSelectedReceiver({
                address: customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0]?.Name ?? '',
                cod: customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0]?.Partner ?? ''
            });

            setSelectedReceiverOld({
                address: customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0]?.Name ?? '',
                cod: customerDataR?.partnerResponseDtos?.filter(pt => pt.Partner === dataR?.receiverCode)?.[0]?.Partner ?? ''
            });
        }

        if (deliveriesR) {
            deliveriesR.forEach(d => {
                if (veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.tm == d.veichleType.tm)?.[0] ?? false) {
                    d.veichleType = veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.tm == d.veichleType.tm)?.[0] ??
                        veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.id == d.veichleType?.id)?.[0];
                }
                else if (veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.id == d.veichleType?.id)?.[0] ?? false) {
                    d.veichleType = veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.id == d.veichleType?.id)?.[0] ??
                        veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms && v.tm == d.veichleType.tm)?.[0];
                }
                else {
                    d.veichleType = veichlesAdd?.filter(v => v.incoterm === dataR?.incoterms)?.[0]
                }

                d?.itens?.forEach(i => {
                    if ((selectedCustomer.country === 'BR' || !selectedCustomer.country)) {
                        if (transporterList?.length !== 0) {
                            if (transporterList?.filter(v => v.cod === i.transp?.cod)?.[0] ?? false) {
                                i.transp = {
                                    cod: transporterList?.filter(v => v.cod === i.transp?.cod)?.[0]?.cod,
                                    name: transporterList?.filter(v => v.cod === i.transp?.cod)?.[0]?.name
                                };
                                i.quantity = i.quantity;
                            }
                        } else {
                            transporterList?.push({
                                cod: deliveriesR?.[0]?.itens?.[0].transp?.cod ?? '',
                                name: deliveriesR?.[0]?.itens?.[0].transp?.name ?? ''
                            });
                        }
                    }
                    i.orderNumber = deliveriesR?.[0]?.itens?.[0].orderNumber ?? '';
                    i.orderItem = deliveriesR?.[0]?.itens?.[0].orderItem ?? '';;
                    i.materialNumber = deliveriesR?.[0]?.itens?.[0].materialNumber ?? '';;
                    i.comment = deliveriesR?.[0]?.itens?.[0].comment ?? '';
                })
            });
        }

        setPaymentConditions(paymentConditionsAdd);
        setTransporters(transporterList);
        setIncoterms(incotermsList);
        setReceivers(receiverList);
        setVeichles(veichlesAdd);
        setDeliveries(deliveriesR);

        const deliveriesOldCopy = JSON.parse(JSON.stringify(deliveriesR));
        setDeliveriesOld(deliveriesOldCopy);
    }

    const getErrorBlock = (error: string) => {
        switch (error) {
            case 'ErrorDateOutOfLimit':
                return t('newOrderScreen.labelErrorDateOutOfLimit');
            case 'ErrorDatePassed':
                return t('newOrderScreen.labelErrorDatePassed');
            case 'ErrorDateEqual':
                return t('newOrderScreen.labelErrorDateEqual');
        }
    }

    useEffect(() => {
        document.querySelectorAll("input[data-input='true']")?.forEach(el => {
            el?.setAttribute('disabled', 'true');
        });
    }, [document.querySelectorAll("input[data-input='true']")]);

    const onChangeIncoterm = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedIncoterm(event.target?.value);

        let paymentConditionsAdd: PaymentCond[] = [];
        if (customerData?.defaultDataResponseDto?.length !== 0) {
            customerData?.defaultDataResponseDto.map(d => {

                var descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition ?? '';

                if(descriptionNew === '' || descriptionNew === null || descriptionNew === undefined)
                    descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: descriptionNew
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            })
        }
        else {

            customerData?.salesDataResponseDto.map(d => {

                var descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === i18n.language.toUpperCase())?.[0]?.Description ?? d.PaymentCondition  ?? '';

                if(descriptionNew === '' || descriptionNew === null || descriptionNew == undefined)
                    descriptionNew = customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === d.PaymentCondition && p.Language === 'PT')?.[0]?.Description ?? d.PaymentCondition  ?? '';

                let paymentNew: PaymentCond = {
                    cod: d.PaymentCondition,
                    description: descriptionNew
                };

                if (!paymentConditionsAdd?.some(i => i.cod === paymentNew.cod)) {
                    paymentConditionsAdd.push(paymentNew);
                }
            })
        }

        deliveries?.forEach(d => {
            let total = 0;
            d.veichleType = veichles?.filter(v => v.incoterm === event.target?.value)?.[0] ??
            {
                tm: '-',
                descriptions: [],
                incoterm: ''
            };
            d.date = undefined;

            d.itens?.forEach(i => {
                i.date = undefined;
                i.quantity = d?.veichleType?.minValue ?? i.quantity;
                total += i.quantity;

                if (event.target?.value === 'CEF' || event.target?.value === 'CPT') {
                    i.transp = {
                        cod: '0000202478',
                        name: 'UNIPAR CARBOCLORO S.A.'
                    }
                } else {
                    const firstTransporter = transporters?.filter(t => t.incoterm === event.target?.value?.toUpperCase() || t.incoterm === 'AF')?.[0] ?? transporters?.[0];
                    i.transp = {
                        cod: firstTransporter?.cod ?? ``,
                        name: firstTransporter?.name ?? ``
                    }
                }

            });

            d.weight = total;
        })

        setSelectedPaymentCondition(paymentConditionsAdd?.[0]);
        setPaymentConditions(paymentConditionsAdd);
    }

    const onChangeReceiver = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedReceiver({
            address: event.target?.selectedOptions[0].text,
            cod: event.target?.value
        });
    }

    function onChangeReceiverNew(receiver: any) {
        
        if(receiver && receiver.length>0)
        {
            setSelectedReceiver({
                address: receiver[0].CNPJ + ' - ' + receiver[0].Name + ' - ' + receiver[0].City + ' - ' + parseInt(receiver[0].Partner),
                cod:  receiver[0].Partner
            });
        }
    }       

    const onChangePayment = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedPaymentCondition({
            description: event.target?.selectedOptions[0].text,
            cod: event.target?.value
        });
       // setchangedOrder(true);
    }

    //TODO: MOVE TO HELPER
    const getNextUtilDate = (date: Date) => {
        const initialDate = new Date(date);
        while (initialDate.toDateString() === date.toDateString() || date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    //TODO: MOVE TO HELPER
    const isValidDate = (e: any): boolean => {
        const date = new Date(e);
        if (!date) return false;

        if (date.toDateString() === new Date().toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidEqualDate'));
            return false;
        }

        if (date < new Date()) {
            setErrorDate(t('newOrderScreen.returnValidPassedDate'));
            return false;
        }

        if ((date.getDay() === 0 || date.getDay() === 6) && !!selectedProduct?.pvc && selectedIncoterm === 'FOB') {
            setErrorDate(t('newOrderScreen.returnValidEndofWeek'));
            return false;
        }

        const dateNow = new Date();
        if ((dateNow.getHours() >= 17 || dateNow.getDay() === 0 || dateNow.getDay() === 6) && date.toDateString() === getNextUtilDate(dateNow).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter17Hour'));
            return false;
        }

        const dateNow4 = new Date();
        if (selectedCustomer.enabledOrderAfterHour !==true && dateNow.getHours() >= 11 && date.toDateString() === getNextUtilDate(dateNow4).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter11Hour'));
            return false;
        }

        const dateNow2 = new Date();
        const dateNowT = new Date();

        var date1 = new Date(date.toDateString());
        var date2 = new Date(dateNowT.toDateString());

        var diffTime = date1.getTime() - date2.getTime();
        var diffDays = diffTime / (1000 * 3600 * 24);

        if (selectedCustomer.enabledOrderAfterHour !==true && dateNow2.getDay() === 5 && dateNow2.getHours() >= 11 && (date.getDay() === 0 || date.getDay() === 6) && diffDays < 7) {
            setErrorDate(t('newOrderScreen.returnValidAfter11FridayHour'));
            return false;
        }

        return true;
    }

    const isValidQuantities = (delivery: Delivery): boolean => {
        return !delivery?.itens?.some(i => i.quantity < delivery.veichleType.minValue) &&
            !delivery?.itens?.some(i => i.quantity > delivery.veichleType.maxValue)
    }

    const onChangeDate = (e: any, id: string) => {

        if (!isValidDate(e)) return;

        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            delivery.date = e;

            delivery.itens?.forEach(i => {
                i.date = delivery.date
            });

            delivery.error = '';

            setDeliveries([...deliveries]);
        }
    }

    const onChangeVeichle = (e: ChangeEvent<HTMLSelectElement>, id: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            const element = veichles?.filter(v => v.id === e.target?.selectedOptions[0].value && v.incoterm === selectedIncoterm && v.descriptions.some(d => d.description === e.target?.selectedOptions[0].text))?.[0] ??
                veichles?.filter(v => v.id === e.target?.selectedOptions[0].value && v.incoterm === selectedIncoterm)?.[0];

            delivery.veichleType = {
                id: e.target?.selectedOptions[0].value ?? '-',
                tm: element?.tm ?? '-',
                incoterm: element?.incoterm ?? 'CEF',
                minValue: element?.minValue ?? 0,
                maxValue: element?.maxValue ?? 0,
                descriptions: element?.descriptions ?? [],
            }

            let total = 0;
            delivery?.itens?.forEach(i => {
                if ((i.products?.length ?? 1) > 1) {
                    recountDeliveryItem(i);
                } else {
                    i.quantity = delivery?.veichleType?.minValue ?? i.quantity;
                    i?.products?.forEach(p => {
                        p.quantity = delivery?.veichleType?.minValue ?? p.quantity;
                    })
                }

                i.error = '';
                total += i.quantity;
            });

            delivery.weight = total;

            setDeliveries([...deliveries]);

        }
    }

    const onChangeTransporter = (e: ChangeEvent<HTMLSelectElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.transp = {
                    name: e.target?.selectedOptions[0].text,
                    cod: e.target?.value
                }
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeQuantityItem = (e: ChangeEvent<FormControlElement>, id: string, idItem: string, idProduct?: string) => {
        const idx = deliveries.findIndex(d => d.id === id);

        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                let product = item?.products?.filter(i => i.productId === idProduct)[0];
                if (product) {
                    product.quantity = (Number(e?.target?.value) ?? 0);
                }

                let total = 0;

                item?.products?.map(t => t.quantity)?.forEach(n => {
                    if(n) total += n;
                });

                item.quantity = (total ?? 0);
                item.error = '';
            }

            if (delivery.weight !== undefined) {
                let total = 0;

                delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                    total += n;
                });

                delivery.weight = total;
            }

            setDeliveries([...deliveries]);
        }
    }

    const onBlurQuantityItem = (e: FocusEvent<FormControlElement, Element>, id: string, idItem: string, idProduct?: string, productMultiple?: any) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];
            let change = false;
            let countOfProducts = item?.products?.length ?? 0;

            if (item) {
                let product = item?.products?.filter(i => i.productId === idProduct)[0];

                if (product) {
                    if (Number(e?.target?.value) <= delivery.veichleType.minValue && countOfProducts <= 1) {
                        product.quantity = delivery.veichleType.minValue;
                        item.error = '';
                        change = true;
                    }
                    else if (Number(e?.target?.value) >= delivery.veichleType.maxValue && countOfProducts <= 1) {
                        product.quantity = delivery.veichleType.maxValue;
                        item.error = '';
                        change = true;
                    } else {
                        if (Number(e?.target?.value) < 0) {
                            product.quantity = productMultiple;
                            change = true;
                        }

                        if (productMultiple != 1 && Number(e?.target?.value) % productMultiple > 0) {
                            change = true;
                            item.quantity = delivery.veichleType.minValue;
                            product.quantity = productMultiple;
                            setInformedQuantity(e?.target?.value);
                            setShowNotMultiple(true);
                        }
                    }
                }

                if (!change) return;

                let total = 0;

                item?.products?.map(t => t.quantity)?.forEach(n => {
                    if(n) total += n;
                });

                item.quantity = (total ?? 0);
                item.error = '';
            }

            if (!change) return;

            if (delivery.weight !== undefined) {
                let total = 0;

                delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                    total += n;
                });

                delivery.weight = total;
            }
           // setchangedOrder(true);
            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderNumber = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.orderNumber = e?.target?.value ?? ''
            }

            setDeliveries([...deliveries]);
          //  setchangedOrder(true);
        }
    }

    const onChangeMaterialNumber = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.materialNumber = e?.target?.value ?? '';
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderItem = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.orderItem = e?.target?.value ?? '';
            }

            setDeliveries([...deliveries]);
        }
    }

    const onChangeOrderItemComment = (e: ChangeEvent<FormControlElement>, id: string, idItem: string) => {
        const idx = deliveries.findIndex(d => d.id === id);
        if (idx > -1) {
            let delivery = deliveries?.filter(d => d.id === id)[0];
            let item = delivery?.itens?.filter(i => i.id === idItem)[0];

            if (item) {
                item.comment = e?.target?.value ?? '';
            }

            setDeliveries([...deliveries]);
        }
    }

    const recountDeliveryWeight = (delivery: Delivery) => {
        if (delivery.weight !== undefined) {
            let total = 0;

            delivery?.itens?.map(t => t.quantity)?.forEach(n => {
                total += n;
            });
            delivery.weight = total;
        }
    }

    const recountDeliveryItem = (item: DeliveryItem) => {
        let total = 0;

        item?.products?.map(p => p.quantity)?.forEach(n => {
            total += n;
        });

        item.quantity = total;
    }

    const changeOnlyCount = (id: string, count: string) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery) return;

        if (count === 'plus') {
            delivery.quantity += 1;
        }
        else if ((delivery.quantity - 1) >= 0) {
            delivery.quantity -= 1;
        }

        recountDeliveryWeight(delivery);
        setDeliveries([...deliveries]);
    }

    const changeDeposit = (id: string, value: string|undefined) => {
        let delivery = deliveries?.filter(d => d.id === id)?.[0];
        if (!delivery || !value) return;

        delivery.deposit = value;

        setDeliveries([...deliveries]);
    }

    const checkHasAnyUpdate = () => {
        for (let d of deliveries) {
            var oldDelivery = deliveriesOld.find(x => x.id === d.id);
            if (oldDelivery) {

                const dDate = new Date(d.date ?? Date.now()).toISOString().split('T')[0];
                const oldDate = new Date(oldDelivery.date ?? Date.now()).toISOString().split('T')[0];

                if (dDate !== oldDate) return true;

                if(selectedCustomer.country === 'AR')
                    if (d.deposit !== oldDelivery.deposit) return true;

                if (d.quantity !== oldDelivery.quantity) return true;
                if (d.weight !== oldDelivery.weight) return true;

                if (d.veichleType.tm !== oldDelivery.veichleType.tm) return true;
                if (d.veichleType.id !== oldDelivery.veichleType.id) return true;

                for (let i of d?.itens || []) {
                    const oldItem = oldDelivery?.itens?.find(oi => oi.id === i.id);

                    if(oldItem){
                        const iDate = new Date(i.date ?? Date.now()).toISOString().split('T')[0];
                        const oldItemDate = new Date(oldItem.date ?? Date.now()).toISOString().split('T')[0];

                        if (iDate !== oldItemDate) return true;

                        if(i.orderItem !== oldItem?.orderItem ) return true;
                        if(i.orderNumber !== oldItem?.orderNumber ) return true;
                        if(i.materialNumber !== oldItem.materialNumber) return true;
                        if(i.quantity !== oldItem.quantity) return true;

                        if(selectedCustomer.country !== 'AR'){
                            if((i.transp?.cod ?? undefined) !== (oldItem.transp?.cod ?? undefined)) return true;
                            if((i.transp?.name ?? undefined) !== (oldItem.transp?.name ?? undefined)) return true;
                        }

                        if(i.comment !== oldItem.comment) return true;

                        for(let p of i?.products || []){
                            const oldProduct = oldItem?.products?.find(op => op.materialCode === p.materialCode);

                            if(oldProduct){
                            if(p.quantity !== oldProduct.quantity) return true;
                            }
                        }
                    }
                }
            }
        }

        if( selectedReceiver?.cod !== selectedReceiverOld?.cod) return true;

        return false;
    };


    const sendOrders = async () => {
        if(!checkHasAnyUpdate()){
            setModalChangedOrder(true);
            return;
        }

        let request: RequestOrderUpdateIntent[] = [];

        for (let d of deliveries) {

            if (!isValidDate(d.date)) return;
            if(selectedCustomer.country === 'AR' && (!d.deposit || d.deposit === '-')) return;
            if (!isValidQuantities(d)) {
                setMsgErrorQuantity(t('messageErrorQuantity'));
                return;
            }

            d?.itens?.forEach((i, idxItem) => {
                if (selectedIncoterm === 'CEF') {
                    i.transp = {
                        cod: '0000202478',
                        name: 'UNIPAR CARBOCLORO S.A.'
                    }
                }

                let truckId = uuidv4();

                const activitiesSector = Array.from(new Set(i?.products?.map(p => p.activitySector)));

                activitiesSector?.forEach((sector) =>{
                    i?.products?.filter(p => p.activitySector === sector).forEach((p, idxP) => {
                        let item: RequestOrderUpdateIntent = {
                            shipmentDate: d.date,
                            shipmentQuantity: p.quantity ?? i.quantity,
                            orderMaterial: i.materialNumber,
                            orderItem: i.orderItem,
                            orderNumber: i.orderNumber,
                            transporterCode: i.transp?.cod,
                            transporterName: i.transp?.name,
                            veichleType: d.veichleType?.tm,
                            veichleTypeName: d.veichleType?.descriptions?.[0]?.description ?? '',
                            comment: obs,
                            receiverCode: selectedReceiver?.cod,
                            isPVC: products?.filter(prd => prd.materialCode === p.materialCode)?.[0].pvc ?? false,
                            materialCode: p?.materialCode,
                            materialDescription: p?.materialDescription,
                            paymentCondition: selectedPaymentCondition?.cod,
                            paymentConditionDescription: selectedPaymentCondition?.description,
                            customerCode: selectedCustomer.issuer,
                            incoterms: selectedIncoterm,
                            unitMeasurement: 'TO',
                            veichleTypeId: d.veichleType?.id,
                            status: StatusIntent.UniparValidation,
                            deposit: d.deposit ?? undefined,
                            intentItem: p?.intentItem,
                            intentNumber: i?.intentNumber,
                            orderIntentGroupId: id === 'null' ? uuidv4().toString() : id ?? '',
                            id: p?.id ?? searchParams.get('idOrder') ?? '',
                            truckId: truckId,
                            commentItem: i.comment
                        };

                        request.push(item);
                    });
                });
            })
        }

        setLoadingCreate(true);
        const result = await putOrderIntent(request, i18n.language.toUpperCase(), loginData?.email ?? '');
        setLoadingCreate(false);
        if (!result.success) {
            if(result.errors){
                setMaterialErrorUpdate(result.errors);
            }else{

                if(result.data)
                {
                    setErrorIntent(result.data);
                    return;
                }

                var arrError = [];
                var error = result.error ?? '';

                if(i18n.language.toUpperCase() === 'PT')
                    error = result.errorPT ?? '';
                if(i18n.language.toUpperCase() === 'EN')
                    error = result.errorEN ?? '';
                if(i18n.language.toUpperCase() === 'ES')
                    error = result.errorES ?? '';

                arrError.push(error);
                setErrorIntent(arrError);
            }

        } else {
            setShowModalSucces(true);
        }
    }

    return (
        <>
            <div className='d-flex new-order-body'>
                <div className='new-order-content'>
                    <div className='new-order-content-header'>
                        <img src={newOrder} />
                        <p>{t('newOrderScreen.newOrderTitle')}</p>
                    </div>
                    <>
                        <div className='new-order-content-from'>
                            <div>
                                <p className='form-title'>
                                    {t('newOrderScreen.labelProductInformation')}
                                </p>

                                <div className='product-info'>
                                    <div className='d-flex flex-column'>
                                        {
                                            materials?.map((material: any) => (
                                                <div>
                                                    <span>{material?.materialCode ? Number(material?.materialCode) : ''} - {material?.description}</span>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className='new-order-snacker'>
                                        <div>
                                            <img id="imgWeight" src={weightTon} alt='icone tonelada' style={{ height: 40 }} />
                                            <div className='new-order-snacker-text'>
                                                <span>{t('newOrderScreen.labelTotalVolume')}</span>
                                                <p>{
                                                    deliveries?.length ?
                                                        deliveries?.map(d => d.weight ?? 0).reduce((acc, cur) => acc + cur, 0) + ' t'
                                                        : '-'
                                                }
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={tuckLocation} alt='icone caminhao' />
                                            <div className='new-order-snacker-text'>
                                                <span>{t('newOrderScreen.labelOrder')}</span>
                                                <p>
                                                    {
                                                        deliveries?.length ?
                                                            deliveries?.map(d => d.itens?.length ?? 0).reduce((acc, cur) => acc + cur, 0)
                                                            : '-'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='divider'></div>
                            </div>

                            <div>
                                <p className='form-title'>
                                    {t('newOrderScreen.labelOrderDetail')}
                                </p>
                                
                                <div className='new-order-selects'>

                                    <div className='select-rm'>
                                        <UniInput
                                            label={t('newOrderScreen.labelDeliveryPlace')}
                                            id='lcl'
                                            isSelect
                                            isAutoComplete
                                            type='select'
                                            loading={loadingProduct}
                                            placeholder={t('newOrderScreen.labelSelect')}
                                            onChangeReceiverAutoComplete={onChangeReceiverNew}
                                            disabled={selectedProducts === undefined}
                                            options={partners ?? []}
                                            clearButton
                                            labelKey={(option: any) => `${option.CNPJ} - ${option.Name} - ${option.City} - ${parseInt(option.Partner)}`}
                                            defaultSelected={(partners && partners.length>0) ? [partners?.filter(d=>d.Partner === selectedReceiver?.cod)?.[0]] : []}
                                        />
                                    </div>                                    

                                    <div className='d-flex  flex-wrap'>
                                        <UniInput label={t('newOrderScreen.labelFreight')} id='frt' loading={loadingProduct} disabled={true} isSelect type='select' defaultValue={incoterms?.[0]} value={selectedIncoterm} placeholder={t('newOrderScreen.labelSelect')} onChange={(e) => onChangeIncoterm(e)}>
                                            <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                            {
                                                incoterms?.map((i, idx) => {
                                                    if (i === 'CEF' || i === 'FOB' || i === 'SFR') {
                                                        return (
                                                            <option value={i} key={idx}>{i === 'CEF' ? t('newOrderScreen.labelCIFFile') : i === 'SFR' ? t('newOrderScreen.labelSFRFile') : t('newOrderScreen.labelFOBFile')}</option>
                                                        )
                                                    }
                                                    if (i === 'FCA' || i === 'CPT') {
                                                        return (
                                                            <option value={i} key={idx}>{i === 'CPT' ? t('newOrderScreen.labelCPTFile') : t('newOrderScreen.labelFCAFile')}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </UniInput>

                                        {
                                            selectedCustomer.country != 'AR' && (
                                                <UniInput label={t('newOrderScreen.labelPaymentConditions')} loading={loadingProduct} id='cond' disabled={true} isSelect type='select' value={selectedPaymentCondition?.cod} defaultValue={paymentConditions?.[0]?.cod} placeholder={t('newOrderScreen.labelSelect')} onChange={(e) => onChangePayment(e)}>
                                                    <option disabled value={'0'}>{t('newOrderScreen.labelSelect')}</option>
                                                    {
                                                        paymentConditions?.map((pt, idx) => {
                                                            return <option key={idx} value={pt.cod}>
                                                                {customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === pt.cod && i18n.language.toUpperCase() === p.Language)?.[0]?.Description ??
                                                                    customerData?.paymentTermResponseDto?.filter(p => p.PaymentCondition === pt.cod && p.Language === 'PT')?.[0]?.Description ?? ''}
                                                            </option>
                                                        })
                                                    }
                                                </UniInput>
                                            )
                                        }

                                    </div>
                                    {deliveries?.length > 0 && <p className='p-changes'>{selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelChangesInformationFOB'): t('newOrderScreen.labelChangesInformationCIF') }</p>}
                                </div>
                            </div>
                        </div>
                        <div className='deliveries'>
                            <div className='d-flex flex-column-reverse'>
                                {
                                    deliveries?.map((delivery, idx) => {
                                        return (
                                            <Accordion key={idx} defaultActiveKey={'0'}>
                                                <Accordion.Item key={idx} eventKey={'0'}>
                                                    <div className='d-flex flex-column wrapper-delivery-form'>
                                                        {delivery.error && <p style={{ color: '#EA2027' }}>{getErrorBlock(delivery.error)}</p>}

                                                        <div className='inputs-body d-flex gap-4 w-100 justify-content-between align-items-center'>
                                                            <div className='dt-tp d-flex gap-4'>
                                                                <div className={selectedCustomer?.country === 'AR' ? 'date-wrapper-ar': 'date-wrapper'}>
                                                                    <label htmlFor='date-picker' style={{ marginBottom: 8 }}>{selectedIncoterm === 'FOB' || selectedIncoterm === 'FCA' ? t('newOrderScreen.labelIncotermDateFOB') : t('newOrderScreen.labelIncotermDateCIF')} *</label>
                                                                    <div className={showErrorForm && delivery.date === undefined ? 'input date input-error' : 'input date'} style={{width: '150px'}}>
                                                                        <DatePicker
                                                                            id='date-picker'
                                                                            clearIcon={null}
                                                                            calendarIcon={<FontAwesomeIcon icon={faCalendarDays} />}
                                                                            dayPlaceholder=''
                                                                            monthPlaceholder=''
                                                                            yearPlaceholder=''
                                                                            onChange={e => onChangeDate(e, delivery.id)}
                                                                            value={delivery.date}
                                                                            closeCalendar
                                                                            minDetail='year'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className={showErrorForm && delivery.veichleType.tm === '-' ? 'input input-error' : 'input'}>
                                                                    <UniInput
                                                                        id='vei'
                                                                        label={t('newOrderScreen.labelVehicleType') + ' *'}
                                                                        isSelect
                                                                        type='select'
                                                                        value={delivery.veichleType.id}
                                                                        disabled
                                                                        placeholder={t('newOrderScreen.labelVehicleType')}
                                                                        defaultValue={'-'}
                                                                        onChange={(e) => onChangeVeichle(e, delivery.id)}
                                                                    >
                                                                        <option disabled value={'-'}>{t('newOrderScreen.labelSelect')}</option>
                                                                        {
                                                                            veichles?.filter(v => v.incoterm === selectedIncoterm)?.map((v, idx) => (
                                                                                <option key={idx} value={v.id}>{v.descriptions.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? v.descriptions.filter(d => d.lang === 'PT')?.[0]?.description ?? ''}</option>
                                                                            ))
                                                                        }
                                                                    </UniInput>
                                                                </div>

                                                                {selectedCustomer?.country === 'AR' &&
                                                                    <div className={showErrorForm && ((selectedCustomer?.country === 'AR' ?? false) && ((selectedCustomer?.country === 'AR' ?? false) && (deliveries?.some(d => !d.deposit || d.deposit === '-') ?? true))) ? 'input input-error' : 'input'}>
                                                                        <UniInput
                                                                            id='deposit'
                                                                            label={t('newOrderScreen.labelDeposit') + ' *'}
                                                                            isSelect
                                                                            type='select'
                                                                            value={delivery.deposit}
                                                                            placeholder={t('newOrderScreen.labelDeposit')}
                                                                            defaultValue={'-'}
                                                                            onChange={(e) => {changeDeposit(delivery.id, e.target.value)}}
                                                                        >
                                                                            <option value={'-'}>{t('newOrderScreen.labelSelect')}</option>
                                                                            <option value='BAHIA BLANCA'>Bahia Blanca</option>
                                                                            <option value='EZEIZA'>Ezeiza</option>
                                                                        </UniInput>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className={size?.width && size?.width < 700 ? 'qtd-counter d-flex w-100 gap-2' : 'qtd-counter d-flex gap-2'}>
                                                                <div className='input-weight input'>
                                                                    <UniInput id='weight' type='text' label={t('newOrderScreen.labelTotalQty')} readOnly placeholder={`${delivery.weight} t`} value={`${delivery.weight} t`} />
                                                                </div>

                                                                <div className='icons-acc align-items-center d-flex'>
                                                                    <AccordionButton />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <Accordion.Body as={`div`} className='delivery-form'>
                                                        {
                                                            delivery.itens?.map((item, key) => {
                                                                return (
                                                                    <div key={key}>
                                                                        {
                                                                            size?.width && size?.width < 992 ? (
                                                                                item?.products?.map((prod, keyP) => (
                                                                                    <div className='wrapper-delivery-form-body' key={keyP}>
                                                                                        <div className='mobile-body-top'>
                                                                                            <div className='body-key'>
                                                                                                {
                                                                                                    keyP == 0 ? (
                                                                                                        <>
                                                                                                            {key + 1}
                                                                                                            <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                            {
                                                                                                                (item?.products?.length ?? 1) > 1 && (
                                                                                                                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                                )
                                                                                                            }
                                                                                                        </>
                                                                                                    ) :
                                                                                                    (
                                                                                                        <div className='v-hidden'>
                                                                                                            {key + 1}
                                                                                                            <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='mobile-body-infos'>
                                                                                            <div className='body-header'>
                                                                                                <label>{t('newOrderScreen.labelDeliveryText')}</label>
                                                                                                {item.date ? moment(item.date)?.format('DD/MM/yyyy') ?? '-' : '-'}
                                                                                            </div>
                                                                                            <div className='body-header'>
                                                                                                <label>{t('newOrderScreen.labelProduct')}</label>
                                                                                                {prod?.materialDescription ?? '-'}
                                                                                            </div>
                                                                                            <div className={showErrorForm && (prod?.quantity === 0 ?? undefined) ? 'input-error' : ''}>
                                                                                                <UniInput
                                                                                                    label={t('newOrderScreen.labelQuantityWeight')}
                                                                                                    type={'number'}
                                                                                                    id='qtd-item'
                                                                                                    htmlLabel={
                                                                                                        <FontAwesomeIcon
                                                                                                            icon={faInfoCircle}
                                                                                                            style={{ marginLeft: 5, color: item?.quantity === 0 ? '#EA2027' : '#ed9306', height: 14, cursor: 'help' }}
                                                                                                            title={(item?.products?.length ?? 1) > 1 ?
                                                                                                                    `${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}` :
                                                                                                                    `${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t ${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}`
                                                                                                                }
                                                                                                        />
                                                                                                    }
                                                                                                    defaultValue={delivery?.veichleType?.minValue ?? 0}
                                                                                                    placeholder={t('newOrderScreen.labelQtyInTruck')}
                                                                                                    value={prod?.quantity === 0 ? '' : prod?.quantity}
                                                                                                    onChangeInput={(e) => onChangeQuantityItem(e, delivery.id, item.id, prod.productId)}
                                                                                                    onBlurInput={(e) => onBlurQuantityItem(e, delivery.id, item.id, prod.productId, customerData?.multipleWeights?.filter(e => e.materialCode === prod.materialCode)?.[0]?.multipleWeight ?? 1)}
                                                                                                    step={customerData?.multipleWeights?.filter(e => e.materialCode === prod.materialCode)?.[0]?.multipleWeight ?? 1}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='body-header'>
                                                                                                <label>{t('newOrderScreen.labelVehicle')}</label>
                                                                                                {delivery?.veichleType?.descriptions?.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? '-'}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                item?.products?.map((prod, keyP) => (
                                                                                    <div className='wrapper-delivery-form-body' key={keyP}>
                                                                                        <div className='body-key'>
                                                                                            {
                                                                                                keyP == 0 ? (
                                                                                                    <>
                                                                                                        {key + 1}
                                                                                                        <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                        {
                                                                                                            (item?.products?.length ?? 1) > 1 && (
                                                                                                                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                ) :
                                                                                                (
                                                                                                    <div className='v-hidden'>
                                                                                                        {key + 1}
                                                                                                        <img src={truckLocation} alt='logo do caminhão' height={40} />
                                                                                                        <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? '#EA2027' : 'transparent', height: 14, cursor: 'help' }} title={(item?.quantity > delivery.veichleType.maxValue || item?.quantity < delivery.veichleType.minValue) ? `${t('totalInVehicle')}: ${item?.quantity}t\n${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t` : ''} />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                        <div className='body-header'>
                                                                                            <label>{t('newOrderScreen.labelDelivery')}</label>
                                                                                            {item.date ? moment(item.date)?.format('DD/MM/yyyy') ?? '-' : '-'}
                                                                                        </div>
                                                                                        <div className='body-header'>
                                                                                            <label>{t('newOrderScreen.labelProduct')}</label>
                                                                                            {prod?.materialDescription ?? '-'}
                                                                                        </div>
                                                                                        <div className={showErrorForm && (prod?.quantity === 0 ?? undefined) ? 'input-error' : ''}>
                                                                                            <UniInput
                                                                                                label={t('newOrderScreen.labelQuantityWeight')}
                                                                                                type={'number'}
                                                                                                id='qtd-item'
                                                                                                htmlLabel={
                                                                                                    <FontAwesomeIcon
                                                                                                        icon={faInfoCircle}
                                                                                                        style={{ marginLeft: 5, color: item?.quantity === 0 ? '#EA2027' : '#ed9306', height: 14, cursor: 'help' }}
                                                                                                        title={(item?.products?.length ?? 1) > 1 ?
                                                                                                                `${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}` :
                                                                                                                `${t('minWeight')}: ${delivery.veichleType.minValue}t \n${t('maxWeight')}: ${delivery.veichleType.maxValue}t ${prod.multiple && prod.multiple != 1 ? `\n${t('multiple')}: ${prod.multiple}t`:''}`
                                                                                                            }
                                                                                                    />
                                                                                                }
                                                                                                defaultValue={delivery?.veichleType?.minValue ?? 0}
                                                                                                placeholder={t('newOrderScreen.labelQtyInTruck')}
                                                                                                value={prod?.quantity === 0 ? '' : prod?.quantity}
                                                                                                onChangeInput={(e) => onChangeQuantityItem(e, delivery.id, item.id, prod.productId)}
                                                                                                onBlurInput={(e) => onBlurQuantityItem(e, delivery.id, item.id, prod.productId, customerData?.multipleWeights?.filter(e => e.materialCode === prod.materialCode)?.[0]?.multipleWeight ?? 1)}
                                                                                                step={customerData?.multipleWeights?.filter(e => e.materialCode === prod.materialCode)?.[0]?.multipleWeight ?? 1}
                                                                                            />
                                                                                        </div>
                                                                                        <div className='body-header'>
                                                                                            <label>{t('newOrderScreen.labelVehicle')}</label>
                                                                                            {delivery?.veichleType?.descriptions?.filter(d => d.lang === i18n.language.toUpperCase())?.[0]?.description ?? '-'}
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            )
                                                                        }

                                                                        <div className='wrapper-delivery-form-inputs'>
                                                                            {
                                                                                selectedIncoterm === 'FOB' && (selectedCustomer.country === 'BR' || !selectedCustomer.country) && (
                                                                                    <div className={showErrorForm && (item.transp?.cod === undefined ?? true) ? 'input-error' : ''}>
                                                                                        <UniInput
                                                                                            id='transp'
                                                                                            label={t('newOrderScreen.labelShippingCompany')}
                                                                                            isSelect
                                                                                            type='select'
                                                                                            placeholder={t('newOrderScreen.labelSelect')}
                                                                                            value={item.transp?.cod}
                                                                                            defaultValue={transporters ? transporters?.filter(t => t.incoterm === selectedIncoterm?.toUpperCase() || t.incoterm === 'AF')?.[0]?.cod ?? transporters[0]?.cod ?? `` : ''}
                                                                                            onChange={(e) => onChangeTransporter(e, delivery.id, item.id)}
                                                                                        >
                                                                                            <option disabled value={''} selected>{t('newOrderScreen.labelSelect')}</option>
                                                                                            {
                                                                                                transporters?.filter(t => t.incoterm === selectedIncoterm?.toUpperCase() || t.incoterm === 'AF')?.map((tr, idx) => {
                                                                                                    return <option key={idx} value={tr.cod}>{tr.name}</option>
                                                                                                })
                                                                                            }
                                                                                        </UniInput>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div>
                                                                                <UniInput
                                                                                    id='numPed'
                                                                                    label={t('newOrderScreen.labelOrderNumber')}
                                                                                    type='text'
                                                                                    placeholder={t('newOrderScreen.labelOrderNumber')}
                                                                                    value={item.orderNumber}
                                                                                    onChangeInput={(e) => onChangeOrderNumber(e, delivery.id, item.id)}
                                                                                    htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <UniInput
                                                                                    id='numMat'
                                                                                    label={t('newOrderScreen.labelMaterialNumber')}
                                                                                    type='text'
                                                                                    placeholder={t('newOrderScreen.labelMaterialNumber')}
                                                                                    value={item.materialNumber}
                                                                                    onChangeInput={(e) => onChangeMaterialNumber(e, delivery.id, item.id)}
                                                                                    htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <UniInput
                                                                                    id='itemPed'
                                                                                    label={t('newOrderScreen.labelOrdemItem')}
                                                                                    type='text'
                                                                                    placeholder={t('newOrderScreen.labelOrdemItem')}
                                                                                    value={item.orderItem?.substring(0, 6)}
                                                                                    maxLength={6}
                                                                                    onChangeInput={(e) => onChangeOrderItem(e, delivery.id, item.id)}
                                                                                    htmlLabel={<FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: '#ad9ead', height: 14, cursor: 'help' }} title={t('newOrderScreen.labelInformationDANFE')} />}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className='wrapper-delivery-form-inputs'>
                                                                            <div className='w-100'>
                                                                                <UniInput
                                                                                    id='itemComment'
                                                                                    label={t('newOrderScreen.labelObservation')}
                                                                                    type='text'
                                                                                    placeholder={t('newOrderScreen.labelObservation')}
                                                                                    value={item.comment}
                                                                                    onChangeInput={(e) => onChangeOrderItemComment(e, delivery.id, item.id)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {item?.error && <p className='w-100 text-center' style={{ color: '#EA2027' }}>{item?.error}</p>}
                                                                        <div className='divider-body'></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        )
                                    })
                                }
                            </div>

                            <div className='buttons-send-order'>
                                <p className={showErrorForm ? 'obs-error' : 'obs'}>
                                    {showErrorForm ? t('newOrderScreen.labelRequiredFieldsValidate') : t('newOrderScreen.labelRequiredFields')}
                                </p>
                                <div>
                                    <Button variant='outline' disabled={loadingProduct || loadingCreate} onClick={() => navigate('/my-orders')}>{t('newOrderScreen.labelBack')}</Button>
                                    <Button
                                        onClick={() => sendOrders()}
                                        disabled={
                                            block ||
                                            loadingCreate ||
                                            (deliveries?.length === 0 ?? true) ||
                                            selectedIncoterm === undefined ||
                                            selectedPaymentCondition === undefined ||
                                            selectedReceiver === undefined ||
                                            loadingProduct
                                        }
                                        title={
                                            selectedReceiver === undefined ? t('newOrderScreen.labelSelectedReceiver') :
                                            selectedIncoterm === undefined ? t('newOrderScreen.labelSelectedIncoterm') :
                                                selectedPaymentCondition === undefined ? t('newOrderScreen.labelPaymentCondition') :
                                                    (deliveries?.length === 0 ?? true) ? t('newOrderScreen.labelMessageDeliveryDate') : t('newOrderScreen.labelSaveChanges')

                                        }
                                        >
                                            { loadingCreate ? <Spinner color='#fff'/> : t('newOrderScreen.labelSaveChanges')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>

                </div>
                <div className='new-order-instructions'>
                    <div className='img'></div>

                    <div className='instructions'>
                        <div className='flag'></div>
                        <p className='instructions-title'>{t('newOrderScreen.rulesTitle')}</p>
                        <p className='instructions-body'>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule1')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule2')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule3')}</p>
                            <p><FontAwesomeIcon icon={faCheckCircle} /> {t('newOrderScreen.rule4')}</p>

                        </p>
                    </div>
                </div>
            </div>

            <UniModal
                icon={tuckLocation}
                title={t('newOrderScreen.labelConfirmAlterOrder')}
                message=''
                show={showModalSucces}
                onHide={() => {
                    setShowModalSucces(false);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={100}
            >
                <div className='modal-body-resume'>
                    <p style={{margin: 0}}>{t('newOrderScreen.labelComingSoon')}</p>
                    <p>{t('newOrderScreen.labelAccessArea')} <span>{t('newOrderScreen.labelMyOrders')}</span></p>
                    <Button onClick={() => navigate('/my-orders')}>{t('newOrderScreen.labelForMyOrders')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faCalendarXmark}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderTextValidDate')}
                message=''
                iconColor='#EA2027'
                show={msgErrorDate !== ''}
                onHide={() => {
                    setErrorDate('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorDate}</p>
                    <Button onClick={() => setErrorDate('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('defaultErrorTitle')}
                message=''
                iconColor='#EA2027'
                show={msgErrorIntent !== undefined}
                onHide={() => {
                    setErrorIntent(undefined);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <div>
                        {
                            // msgErrorIntent?.map((d, key) => {

                            //     if(i18n.language.toUpperCase() === 'PT')
                            //         return (<p> - {d.errorPT ?? d.error}</p>)

                            //     if(i18n.language.toUpperCase() === 'ES')
                            //         return (<p> - {d.errorES ?? d.error}</p>)

                            //     if(i18n.language.toUpperCase() === 'EN')
                            //         return (<p> - {d.errorEN ?? d.error}</p>)

                            //     return (<p> - {d.error}</p>)

                            //})
                            t('defaultErrorBody')
                        }
                    </div>
                </div>
            </UniModal>

            <UniModal
                icon={faWarning}
                isFontAwsome
                title={t('newOrderScreen.labelMultipleQuantityError')}
                message=''
                iconColor='#ECEF4E'
                show={showNotMultiple}
                onHide={() => {
                    setShowNotMultiple(false);
                }}
                iconWidth={50}
                iconHeight={60}
            >
                <div className='modal-body-resume'>
                    <p>{t('newOrderScreen.labelMultipleQuantityError').replace('[informedQuantity]', informedQuantity).replace('[multipleWeight]', multipleWeight?.multipleWeight?.toString())}</p>
                    <Button onClick={() => setShowNotMultiple(false)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('newOrderScreen.labelFileModel')}
                message=''
                iconColor='#EA2027'
                show={msgErrorFileModel !== ''}
                onHide={() => {
                    setErrorFileModel('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorFileModel}</p>
                    <Button onClick={() => setErrorFileModel('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faInfoCircle}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderTextValidQuantity')}
                message=''
                iconColor='#EA2027'
                show={msgErrorQuantity !== ''}
                onHide={() => {
                    setMsgErrorQuantity('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorQuantity}</p>
                </div>
            </UniModal>

            <UniModal
                icon={faXmarkCircle}
                isFontAwsome
                title={t('defaultErrorTitle')}
                message=''
                iconColor='#EA2027'
                show={materialErrorUpdate !== undefined}
                onHide={() => {
                    setMaterialErrorUpdate(undefined);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <div>
                        {
                           t('defaultErrorBody')
                        }
                    </div>
                </div>
            </UniModal>

            <UniModal
                icon={faWarning}
                isFontAwsome
                title={t('anyChangeOrderTitle')}
                message=''
                iconColor='#ECEF4E'
                show={modalChangedOrder}
                onHide={() => {
                    setModalChangedOrder(false);
                }}
                iconWidth={50}
                iconHeight={60}
            >
                <div className='modal-body-resume'>
                    <p>{t('anyChangeOrderBody')}</p>
                    <Button onClick={() => setModalChangedOrder(false)}>{t('myOrders.close')}</Button>
                </div>
            </UniModal>
        </>
    )
}

export default EditOrder;