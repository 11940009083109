import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './UniInput.scss';
import { FormControlProps, FormSelectProps, Spinner } from 'react-bootstrap';
import { ChangeEvent, FocusEvent, ReactNode, SelectHTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { isDisabled } from '@testing-library/user-event/dist/utils';

export type FormControlElement = HTMLInputElement | HTMLTextAreaElement;
interface UniInputProps {
    label?: string,
    theme?: string,
    placeholder?: string,
    id: string,
    type?: string,
    props?: FormControlProps,
    isSelect?: boolean,
    children?: ReactNode,
    disabled?: boolean,
    enterKey?: any,
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
    onChangeInput?: (e: ChangeEvent<FormControlElement>) => void
    onBlurInput?: (e: FocusEvent<FormControlElement, Element>) => void
    defaultValue?: any
    value?: any
    readOnly?: boolean,
    loading?: boolean,
    htmlLabel?: ReactNode,
    iconBtn?: boolean,
    iconRightBtn?: boolean,
    iconRightBtnType?: IconDefinition,
    onClickIconBtn?: () => void
    onClickIconRightBtn?: () => void,
    onFocus?: (e: FocusEvent<FormControlElement, Element>) => void,
    maxLength?: number,
    step?: number,
    isAutoComplete?:boolean,
    options?: string[] | Record<string, any>[],
    labelKey?: (option: any) => string,
    onChangeReceiverAutoComplete?: (params: any) => void,
    defaultSelected?: undefined | Record<string, any>[],
    clearButton?: boolean
};

const UniInput = (props: UniInputProps) => {
    return (
        props.isSelect ? 
            !props.isAutoComplete ?
            (
                <div className='select'>
                    {props.label && <Form.Label htmlFor={props.id}>{props.label}{props.htmlLabel}</Form.Label>}
                    {
                        !!props.loading ? (
                            <div className='spinner-wrapper'>
                                <Spinner></Spinner>
                            </div>
                        ) : (
                            <InputGroup className="mb-3">
                                <Form.Select placeholder={props.placeholder} disabled={props.disabled} defaultValue={props.defaultValue} value={props.value} onChange={(e) => {
                                    if(props.onChange)
                                        props.onChange(e)
                                }}>
                                    {props.children}
                                </Form.Select>
                            </InputGroup>
                        )
                    }
                </div>
            ) : 
            (
                <div className='select'>                                                
                    {props.label && <Form.Label htmlFor={props.id}>{props.label}{props.htmlLabel}</Form.Label>}
                    {
                        !!props.loading ? (
                            <div className='spinner-wrapper'>
                                <Spinner></Spinner>
                            </div>
                        ) : (                        
                        <Typeahead
                            onChange={props.onChangeReceiverAutoComplete}
                            clearButton={props.clearButton}
                            disabled={props.disabled ?? false}
                            options={props.options ?? []}
                            labelKey={props.labelKey}
                            defaultSelected={props.defaultSelected}
                            style={{width:'90%'}}           
                        />)
                    }                                        
                </div>
            )        
        : (
            <>
                {props.label && <Form.Label htmlFor={props.id}>{props.label}{props.htmlLabel}</Form.Label>}
                {
                    !!props.loading ? (
                        <div className='spinner-wrapper'>
                            <Spinner></Spinner>
                        </div>
                    ) : (
                        <InputGroup className="mb-3">
                            <Form.Control
                                id={props.id}
                                type={props.type ?? `text`}
                                placeholder={props.placeholder}
                                readOnly={props.readOnly}
                                onKeyDown={props.enterKey}
                                onChange={(e) => {
                                    if(props.onChangeInput)
                                        props.onChangeInput(e)
                                }}
                                onBlur={(e) => {
                                    if(props.onBlurInput)
                                        props.onBlurInput(e)
                                }}
                                value={props.value}
                                {...props.props}
                                disabled={props.disabled}
                                onFocus={(e) => {
                                    if(props.onFocus)
                                        props.onFocus(e)
                                }}
                                maxLength={props.maxLength}
                                step={props.step}
                            />

                            {
                                (props.iconRightBtn && props.iconRightBtnType) && (
                                    <InputGroup.Text
                                        onClick={() => {if (props.onClickIconRightBtn) props.onClickIconRightBtn()}}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <FontAwesomeIcon icon={props.iconRightBtnType} />
                                    </InputGroup.Text>
                                )
                            }
                            {
                                props.iconBtn && (
                                    <FontAwesomeIcon title='Buscar' icon={faSearch} style={{color: 'white', cursor: 'pointer', marginLeft: 10, alignSelf: 'center'}} onClick={() => { if (props.onClickIconBtn) props.onClickIconBtn()}}/>
                                )
                            }
                        </InputGroup>
                    )
                }
            </>

        )
    );
}

export default UniInput;